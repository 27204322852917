import React, { useState, useEffect } from 'react';
import data from './data.json';
import { Helmet } from 'react-helmet'; // Add this import
import seedrandom from 'seedrandom';

function Game() {
  const [gameData, setGameData] = useState([]);
  const [difficulty, setDifficulty] = useState('Medium');
  const [unplayedGames, setUnplayedGames] = useState([]);
  const [currentGame, setCurrentGame] = useState(null);
  const [currentGuess, setCurrentGuess] = useState([]);
  const [foundGroups, setFoundGroups] = useState({});
  const [attempts, setAttempts] = useState(4);
  const [message, setMessage] = useState('');
  const [animation, setAnimation] = useState(null);
  const [animationWords, setAnimationWords] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [gameId, setGameId] = useState(null); // Add this line
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [selectionStreak, setSelectionStreak] = useState([]);

  const levelColors = ['#fbd400', '#b5e352', '#729eeb', '#bc70c4'];
  const levelEmojis = ["🔵", "🟢", "🟡", "🔴", "🟣"]; // Choose your own emojis

  
  useEffect(() => {
    setTimeElapsed(0);  // Reset the timer when starting a new game
  
    // Other codes
  
    const timer = setInterval(() => {
      setTimeElapsed(timeElapsed => timeElapsed + 1);
    }, 1000);
  
    return () => clearInterval(timer);
  }, [gameId]);  // Only depend on gameId

  function mixGroups(gameId, difficulty) {
    const rng = seedrandom(gameId); // Create seeded random number generator
    let newGame = { groups: {}, startingGroups: [[], [], [], []] };
    let usedWords = new Set();
  
    // Define difficulty levels
    let levels = [];
    switch(difficulty){
      case "Easy":
        levels = [0, 1];
        break;
      case "Hard":
        levels = [2, 3];
        break;
      default: // Medium
        levels = [0, 1, 2, 3];
        break;
    }
    
    for(let i=0; i<4; i++) {
      let randomId, randomGame, groupKey;
  
      // Keep trying until a suitable group is found
      do {
        do {
          randomId = Math.floor(rng() * data.length);
        } while(randomId === gameId); // Ensure that we don't select the same game
  
        randomGame = data[randomId];
  
        do {
          groupKey = Object.keys(randomGame.groups)[Math.floor(rng() * Object.keys(randomGame.groups).length)];
        } while(!levels.includes(randomGame.groups[groupKey].level)); // Check if the group's level is included in the selected levels
  
      } while(randomGame.groups[groupKey].members.some(member => usedWords.has(member))); // Check if the members are already used
  
      newGame.groups = {...newGame.groups, [groupKey]: randomGame.groups[groupKey]};
          
      // Shuffle members into startingGroups
      randomGame.groups[groupKey].members.forEach((member, index) => {
        usedWords.add(member); // Add the member to the usedWords set
  
        let insertionRow;
        do {
          insertionRow = Math.floor(rng() * 4);
        } while (newGame.startingGroups[insertionRow].length === 4);
        newGame.startingGroups[insertionRow].push(member);
      });
    }
    
    return newGame;
  }
  
  const shuffleArray = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };
  

  // useEffect(() => {
  //   setGameData(data);
  //   setTimeElapsed(0);
  //   setUnplayedGames([...Array(data.length).keys()]);
  //   let gameIndex;

  //   const urlParams = new URLSearchParams(window.location.search);
  //   const gameId = urlParams.get('gameId');
  //   setGameId(gameId);

  //   const difficulty = urlParams.get('difficulty') || 'Medium'; // default to Medium
  //   setDifficulty(difficulty);

  //   if(gameId){
  //       gameIndex = gameId;
  //       const mixedGame = mixGroups(gameIndex, difficulty);
  //       setCurrentGame(mixedGame);
  //   } else {
  //       gameIndex = generateIndexConnections() % data.length;
  //       setCurrentGame(data[gameIndex]);
  //   }

  //   setUnplayedGames(prevUnplayedGames => prevUnplayedGames.filter(index => index !== gameIndex));
  //   // Reset the timer and selection streak when a new game is started
  //   setSelectionStreak([]);
  // }, [gameId]);
  useEffect(() => {
    setGameData(data);
    setTimeElapsed(0);
    setUnplayedGames([...Array(data.length).keys()]);
  
    const urlParams = new URLSearchParams(window.location.search);
    const gameId = urlParams.get('gameId');
    setGameId(gameId);
  
    const difficulty = urlParams.get('difficulty') || 'Medium'; // default to Medium
    setDifficulty(difficulty);
  
    let gameIndex;
  
    if (gameId) {
      gameIndex = gameId;
    } else {
      gameIndex = generateIndexConnections() % data.length;
    }
  
    const mixedGame = mixGroups(gameIndex, difficulty);
    setCurrentGame(mixedGame);
    setUnplayedGames(prevUnplayedGames => prevUnplayedGames.filter(index => index !== gameIndex));
    // Reset the timer and selection streak when a new game is started
    setSelectionStreak([]);
  }, [gameId]);
  

  const changeDifficulty = (newDifficulty) => {
    // Generate new random index
    const randomIndex = Math.floor(Math.random() * unplayedGames.length);
    const nextGameIndex = unplayedGames[randomIndex];
  
    // Get the current path
    const currentPath = window.location.pathname;
  
    // Update the URL to include new gameId and difficulty
    window.location.href = window.location.origin + currentPath + '?gameId=' + nextGameIndex + '&difficulty=' + newDifficulty;
  };

  function generateIndexConnections() {
    const D = 864e5;
    const e = new Date("16/03/2024");
    const t = (new Date()).setHours(0, 0, 0, 0) - e.setHours(0, 0, 0, 0);
    let n = Math.round(t / D);
    return n < 0 ? Math.abs(n) : n;
  }

  const handleWordClick = word => {
    // If the word is already selected, remove it from the currentGuess
    if (currentGuess.includes(word)) {
      setCurrentGuess(currentGuess.filter(guess => guess !== word));
    } 
    // Else if there are less than 4 items in the currentGuess, add the word
    else if (currentGuess.length < 4) {
      setCurrentGuess([...currentGuess, word]);
    } 
    // If there are 4 items already and the word isn't one of them, ignore the click
    else {
      return;
    }
  };

  // const submitGuess = () => {
  //   if (currentGuess.length !== 4) {
  //     setMessage('You need to select 4 items before submitting');
  //     return;
  //   }
  
  //   const guessedGroup = Object.entries(currentGame.groups).find(
  //     ([groupName, group]) =>
  //       JSON.stringify(group.members.sort()) === JSON.stringify(currentGuess.sort())
  //   );
  
  //   if (guessedGroup) {
  //     setFoundGroups({
  //       ...foundGroups,
  //       [guessedGroup[0]]: guessedGroup[1].members,
  //     });
  
  //     const newStartingGroups = currentGame.startingGroups
  //       .flat()
  //       .filter(word => !currentGuess.includes(word));
  
  //     setCurrentGame({
  //       ...currentGame,
  //       startingGroups: chunkArray(newStartingGroups, 4),
  //     });
      
  //     setMessage('Congratulations! You have found a group!');
  //     setAnimation('success');
  //     setAnimationWords(currentGuess);
  //     setTimeout(() => {
  //       setCurrentGuess([]);
  //       setAnimation(null);
  //       setAnimationWords([]);
  //     }, 1000);
  //   } else {
  //     setCurrentGuess([]); // Deselect all items
  //     setAttempts(prevAttempts => {
  //       if (prevAttempts - 1 === 0) {
  //         setGameOver(true);
  //         setMessage('Game Over! All attempts used up!');
  //       }
  //       return prevAttempts - 1;
  //     });
  //     setMessage('Incorrect guess, please try again');
  //     setAnimation('error');
  //     setAnimationWords(currentGuess);
  //     setTimeout(() => {
  //       setAnimation(null);
  //       setAnimationWords([]);
  //     }, 1000);
  //   }
  
  //   const wordLevels = currentGuess.map((word) => {
  //     // Find the group to which the word belongs
  //     const groupName = Object.keys(currentGame.groups).find(
  //       key => currentGame.groups[key].members.includes(word)
  //     );
      
  //     // Get the level of this group
  //     const level = currentGame.groups[groupName].level;
      
  //     // Return the word and its corresponding level
  //     return { word, level };
  //   });
  
  //   setSelectionStreak([...selectionStreak, wordLevels]);
  // };
  const submitGuess = () => {
    if (currentGuess.length !== 4) {
      setMessage('You need to select 4 items before submitting');
      return;
    }

    const guessedGroup = Object.entries(currentGame.groups).find(
      ([groupName, group]) =>
        JSON.stringify(group.members.sort()) === JSON.stringify(currentGuess.sort())
    );

    if (guessedGroup) {
      setFoundGroups({
        ...foundGroups,
        [guessedGroup[0]]: guessedGroup[1].members,
      });

      const newStartingGroups = currentGame.startingGroups
        .flat()
        .filter(word => !currentGuess.includes(word));

      setCurrentGame({
        ...currentGame,
        startingGroups: chunkArray(newStartingGroups, 4),
      });

      setMessage('Congratulations! You have found a group!');
      setAnimation('success');
      setAnimationWords(currentGuess);
      setTimeout(() => {
        setCurrentGuess([]);
        setAnimation(null);
        setAnimationWords([]);
      }, 1000);
    } else {
      setCurrentGuess([]); // Deselect all items
      setAttempts(prevAttempts => {
        if (prevAttempts - 1 === 0) {
          setGameOver(true);
          setMessage('Game Over! All attempts used up!');
        }
        return prevAttempts - 1;
      });
      setMessage('Incorrect guess, please try again');
      setAnimation('error');
      setAnimationWords(currentGuess);
      setTimeout(() => {
        setAnimation(null);
        setAnimationWords([]);
      }, 1000);
    }

    const wordLevels = currentGuess.map((word) => {
      // Find the group to which the word belongs
      const groupName = Object.keys(currentGame.groups).find(
        key => currentGame.groups[key].members.includes(word)
      );

      // Get the level of this group
      const level = currentGame.groups[groupName].level;

      // Return the word and its corresponding level
      return { word, level };
    });

    setSelectionStreak([...selectionStreak, wordLevels]);
  };

  const AttemptsDisplay = ({ attempts }) => {
    const dots = Array.from({ length: attempts }, (_, index) => <div className="dot" key={index}></div>);

    return (
      <div className="attempts-display">
        {dots}
      </div>
    );
  };
  
  const startNewGame = () => {
    if (unplayedGames.length === 0) {
      setMessage('No more games left!');
      return;
    }
  
    const randomIndex = Math.floor(Math.random() * unplayedGames.length);
    const nextGameIndex = unplayedGames[randomIndex];
    setUnplayedGames(prevUnplayedGames => prevUnplayedGames.filter(index => index !== nextGameIndex));
  
    // Get the current path
    const currentPath = window.location.pathname;
  
    // Redirect to new page with gameId and difficulty as URL parameters
    window.location.href = window.location.origin + currentPath + `?gameId=${nextGameIndex}&difficulty=${difficulty}`;
  };

  const restartGame = () => {
    window.location.reload();
  };

  const resetGuess = () => {
    setCurrentGuess([]);
  };

  const copyResultsToClipboard = () => {
    // Create a string from the selection streak
    const resultsString = selectionStreak.map((guess, index) => 
      guess.map((wordObj) => `${levelEmojis[wordObj.level]}`).join('')
    ).join('');
    window.focus();
    // Copy the string to the clipboard
    navigator.clipboard.writeText('unscrambleit.net/connections-nyt-unlimited/ - ' + resultsString).then(function(){
      alert('Copied to Clipboard, you can now share the result in social media.');
    });
    // Show an alert message
  };
  
  

  return (
    <>
    <div className="game-container">
      {gameId && (
        <Helmet>
        </Helmet>
      )}
      
      <div class="game-header">
        <h2 className='fs-3 fw-bold'>Connections NYT Unlimited</h2>
      </div>
      <div>
        <div className="difficulty d-flex justify-content-center text-center fw-bold my-3">Select Game Mode:</div>
        <div className="d-flex flex-nowrap gap-3 mb-3">
          <button
            className={`btn btn-outline-dark rounded-pill difficulty-button ${difficulty === 'Easy' ? 'active' : ''}`}
            onClick={() => changeDifficulty('Easy')}
          >
            Easy
          </button>
          <button
            className={`btn btn-outline-dark rounded-pill difficulty-button ${difficulty === 'Medium' ? 'active' : ''}`}
            onClick={() => changeDifficulty('Medium')}
          >
            Medium
          </button>
          <button
            className={`btn btn-outline-dark rounded-pill difficulty-button ${difficulty === 'Hard' ? 'active' : ''}`}
            onClick={() => changeDifficulty('Hard')}
          >
            Hard
          </button>
        </div>
      </div>
      
      <div className="top-g"><p className="p-text">Create four groups of four.</p><div className="game-time">Time: {`${Math.floor(timeElapsed / 60).toString().padStart(2, '0')}:${(timeElapsed % 60).toString().padStart(2, '0')}`}</div></div>
              
        
      {Object.entries(foundGroups).map(([groupName, words], index) => (
        <div
          className="game-group"
          key={groupName}
          style={{ backgroundColor: levelColors[index % levelColors.length] }}
        >
          <h3 className="group-name">{groupName}</h3>
          <div className="group-members">{words.join(', ')}</div>
        </div>
      ))}


      {currentGame && currentGame.startingGroups.map((group, groupIndex) => (
        <div className="game-board" key={groupIndex}>
          {group.map((word, wordIndex) => (
         
          <button
            className={`game-item 
              ${currentGuess.includes(word) ? 'selected' : ''} 
              ${animationWords.includes(word) ? `${animation}-animation` : ''} 
              ${word.length === 8 ? 'size-8' : ''} 
              ${word.length > 8 ? 'size-more' : ''}`}
            key={wordIndex}
            onClick={() => !gameOver && handleWordClick(word)} // Disable onClick when gameOver is true
          >
            {word || ' '}
          </button>

          ))}
        </div>
      ))}
      {message && <div className="message">{message}</div>}
      {gameOver ? (
        <>
        <div className='d-flex flex-nowrap gap-3 btn-wrapper'>
          <button className="game-btn btn btn-dark rounded-pill px-md-4 px-2" onClick={restartGame}>Restart</button>
          <button className="game-btn btn btn-outline-dark rounded-pill" onClick={startNewGame}>Start New Game</button>
          </div>
        </>
      ) : (
        <>
          {currentGame && Object.keys(foundGroups).length === Object.keys(currentGame.groups).length ? (
            <>
                <div className="congratulations">
                    <h2 className='fs-4' >Congratulations!</h2>
                    <p>You have found all groups!</p>
                    <div className="share">
  {selectionStreak.map((guess, index) => (
    <div key={index} className="share-row">
      {guess.map((wordObj, i) => (
        <span key={i} style={{ backgroundColor: levelColors[wordObj.level] }}>
          
        </span>
      ))}
    </div>
  ))}
</div>
<button className="game-btn" style={{ backgroundColor:'#000000', color:'#fff', marginTop:'10px' }} onClick={copyResultsToClipboard}>Share Results</button>
                </div>
                <button className="game-btn" onClick={startNewGame}>Start New Game</button>
            </>
        
          ) : (
            <div className="btn-wrapper">
              <button className="game-btn submit-btn btn btn-dark rounded-pill" onClick={submitGuess}>Submit</button>
              <button className="game-btn btn btn-outline-dark rounded-pill" onClick={resetGuess}>Deselect</button>
              <button className="game-btn btn btn-outline-dark rounded-pill" onClick={startNewGame}>Start New Game</button>
            </div>
          )}
          <div className="game-attempts">Mistakes remaining: <AttemptsDisplay attempts={attempts} /></div>
        </>
      )}
    </div>
    </>
  );
}

function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

export default Game;